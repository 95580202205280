import { DateTime } from "luxon";
export const formatDate = (inDate) => {
  return inDate
    ? DateTime.fromISO(inDate).toLocaleString(DateTime.DATE_SHORT)
    : "N/A";
};
export const formatCustomer = (customer) => {
  let toDisplay = "";
  if (!customer) return toDisplay;
  if (customer.name) {
    toDisplay += customer.name;
  }
  if (customer.phone_number) {
    toDisplay += ` (${customer.phone_number}) `;
  }
  if (customer.email_address) {
    toDisplay += ` (${customer.email_address})`;
  }
  return toDisplay;
};

export const formatVariant = (variant) => {
  let toDisplay = "";
  if (!variant) return toDisplay;
  if (variant.species) {
    toDisplay += `${variant.species.scientific_name} -`;
  }
  if (variant.description) {
    toDisplay += ` ${variant.description}`;
  }
  if (variant.species?.common_name) {
    toDisplay += ` ${variant.species.common_name}`;
  }
  if (variant.collection_location) {
    toDisplay += `, ${variant.collection_location}`;
  }
  return toDisplay;
};
